'use strict';

Gri.module({
  name: 'input-beta',
  ieVersion: null,
  $el: $('.input-beta'),
  container: '.input-beta',
  fn: function () {
    
  }
});
